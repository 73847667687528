import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { baseUrl } from "../../Api/BaseApi";
import { IOrder, Order as API, TTab } from "../../Api/Order";
import { User as userAPI } from "../../Api/User";
import DatePicker from "react-datepicker";
import Page from "../../Component/Page/Page";
import useEffectWithInterval from "../../hook/useEffectWithInterval";
import { Credentials } from "../../Store/Credentials";
import styles from "./orders.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface IOption {
    value: number;
    label: string;
}

const Orders = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [quantity, setQuantity] = useState<number>(0);
    const [totalTaxable, setTotalTaxable] = useState<number>(0);
    const [totalOwed, setTotalOwed] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [user, setUser] = useState<IOption | null>(null);
    const [users, setUsers] = useState<IOption[]>([]);
    const [totalpages, setTotalpages] = useState<number>(1);
    const [tab, setTab] = useState<TTab>("all");
    const [admin] = useState<number>(Credentials.admin);
    const [searchTerm, setSearchTerm] = useState("");
    const [delayedSearchTerm, setDelayedSearchTerm] = useState("");

    const loadUsers = useCallback(async () => {
        const usersFromApi = await userAPI.List();
        const userOptions = usersFromApi.map((user) => ({
            value: +user.id,
            label: `${user.name}`
        }));

        setUsers(userOptions);

        if (admin !== 1) {
            const myOption = userOptions.filter((user) => user.value === Credentials.id)[0];
            setUser(myOption);
        }
    }, [admin]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const changeTab = useCallback((tabName: TTab) => {
        setPage(1);
        setTab(tabName);
    }, []);

    const loadList = useCallback(async () => {
        setIsLoading(true);

        setOrders([]);
        const productData = await API.List(page, user?.value || null, tab, delayedSearchTerm);
        setOrders(productData.list);
        setQuantity(productData.quantity);
        setTotalTaxable(productData.total_taxable);
        setTotalOwed(productData.total_owed);

        setTotalpages(Math.ceil(productData.quantity / 10));

        setIsLoading(false);
    }, [page, user, tab, delayedSearchTerm]);

    useEffect(() => {
        setPage(1);
    }, [user]);

    useEffectWithInterval(() => setDelayedSearchTerm(searchTerm), [searchTerm], 1000);

    useEffectWithInterval(loadList, [loadList, page, user, tab, delayedSearchTerm], 100);

    return (
        <Page showNav={true}>
            <div className={styles["orders"]}>
                <ul className="nav nav-pills m-1 ">
                    <li className="nav-item ">
                        <button
                            className={classNames("nav-link", tab === "all" && "active")}
                            aria-current="page"
                            onClick={() => changeTab("all")}
                        >
                            All
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={classNames("nav-link", tab === "free" && "active")}
                            onClick={() => changeTab("free")}
                        >
                            Free
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={classNames("nav-link", tab === "paid" && "active")}
                            onClick={() => changeTab("paid")}
                        >
                            Paid
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={classNames("nav-link", tab === "unpaid" && "active")}
                            onClick={() => changeTab("unpaid")}
                        >
                            Unpaid
                        </button>
                    </li>
                </ul>
                <Select
                    isSearchable={false}
                    className={classNames("m-1", styles["list-category"])}
                    value={user}
                    options={users}
                    isDisabled={!admin}
                    placeholder={"Select user"}
                    onChange={(newValue) => setUser(newValue)}
                    isClearable
                />
                <div className={styles["list-header"]}>
                    <div>
                        Displaying {orders.length} of {quantity} products.
                    </div>
                    <div>
                        Total ${totalTaxable.toFixed(2)} worth ~ ${totalOwed.toFixed(2)} taxable
                    </div>
                    <div className={styles["search-term"]}>
                        <input
                            className={styles["search-input"]}
                            placeholder="Search term"
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles["order-list"]}>
                    {orders &&
                        orders.map((order, index) => (
                            <div className={styles["order-row"]} key={index}>
                                <div className={styles["product-image"]}>
                                    {order.name && (
                                        <img
                                            alt={"img error"}
                                            src={`https://file.llamastories.com/${order.ASIN}.jpg`}
                                        ></img>
                                    )}
                                </div>
                                <div className={styles["product-details"]}>
                                    <a
                                        target="_blank"
                                        href={`https://www.amazon.com/dp/${order.ASIN}`}
                                        rel="noreferrer noopener"
                                        title={order.name}
                                        className={styles["product-name"]}
                                    >
                                        {order.name ||
                                            `${order.ASIN} - This item is no longer available`}
                                    </a>
                                    <div className={styles["inner-details"]}>
                                        {admin > 0 && (
                                            <div className={styles["order-by"]}>
                                                <Select
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    className={classNames(
                                                        "m-1",
                                                        styles["list-category"]
                                                    )}
                                                    value={users.filter(
                                                        (option) => option.value === order.user_id
                                                    )}
                                                    options={users}
                                                    isDisabled={isLoading || admin !== 1}
                                                    placeholder={"Select user"}
                                                    onChange={async (newValue) => {
                                                        setIsLoading(true);
                                                        const newuUser = newValue?.value || 0;

                                                        const result = await API.UpdateUser(
                                                            order.ASIN,
                                                            newuUser
                                                        );
                                                        if (result) {
                                                            loadList();
                                                        } else {
                                                            alert("Error updating user.");
                                                        }
                                                        setIsLoading(false);
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className={styles["date-and-price"]}>
                                            <div>
                                                {order.price > 0 ? `$${order.price}` : "free"} -{" "}
                                                {order.requested_date}
                                            </div>
                                            {order.price > 0 && (
                                                <>
                                                    {admin === 1 ? (
                                                        <div className={styles["paid-picker"]}>
                                                            <div className={styles["paid-label"]}>
                                                                Paid:
                                                            </div>
                                                            <DatePicker
                                                                disabled={isLoading}
                                                                selected={
                                                                    order.paid_date
                                                                        ? new Date(order.paid_date)
                                                                        : null
                                                                }
                                                                onChange={async (date: Date) => {
                                                                    setIsLoading(true);
                                                                    const newDate = date
                                                                        ? moment(date).format(
                                                                              "YYYY-MM-DD"
                                                                          )
                                                                        : "";

                                                                    const result =
                                                                        await API.UpdatePaid(
                                                                            order.ASIN,
                                                                            newDate
                                                                        );

                                                                    if (result) {
                                                                        loadList();
                                                                    } else {
                                                                        alert(
                                                                            "Error updating paid date."
                                                                        );
                                                                    }
                                                                    setIsLoading(false);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div>Paid: {order.paid_date || "-"}</div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={styles["list-footer"]}>
                    <ReactPaginate
                        key={page}
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={({ selected }) => setPage(selected + 1)}
                        initialPage={page - 1}
                        pageRangeDisplayed={2}
                        pageCount={totalpages}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName={classNames("pagination", styles["pagination"])}
                        activeClassName="active"
                    />
                </div>
            </div>
        </Page>
    );
};

export default Orders;
