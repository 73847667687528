import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { baseUrl } from "../../Api/BaseApi";
import { IReview, Review as API, TTab } from "../../Api/Review";
import { Order as OrderAPI } from "../../Api/Order";
import { User as userAPI } from "../../Api/User";
import Page from "../../Component/Page/Page";
import useEffectWithInterval from "../../hook/useEffectWithInterval";
import { Credentials } from "../../Store/Credentials";
import styles from "./reviews.module.scss";
import "react-datepicker/dist/react-datepicker.css";

interface IOption {
    value: number;
    label: string;
}

const buildReviewLink = (review: IReview) => {
    return `mailto:vine@davila.one?
subject=Vine Review: ${review.ASIN} - ${review.requested_date} : ${review.name} 
&body=Headline:${encodeURIComponent("\n\n")}
Star rating (1-5):${encodeURIComponent("\n\n")}
Review (A couple paragraphs min, bullet points, or pos/cons):${encodeURIComponent("\n\n\n\n")}
Read reviews for inspiration, attach pictures${encodeURIComponent("\n")}
Review (this link is for me): https://www.amazon.com/review/create-review?asin
${encodeURIComponent("=")}${review.ASIN}${encodeURIComponent("\n\n")}`;
};

const Reviews = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reviews, setReviews] = useState<IReview[]>([]);
    const [quantity, setQuantity] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [user, setUser] = useState<IOption | null>(null);
    const [users, setUsers] = useState<IOption[]>([]);
    const [statusList, setStatusList] = useState<IOption[]>([]);
    const [totalpages, setTotalpages] = useState<number>(1);
    const [tab, setTab] = useState<TTab>("todo");
    const [admin] = useState<number>(Credentials.admin);
    const [searchTerm, setSearchTerm] = useState("");
    const [delayedSearchTerm, setDelayedSearchTerm] = useState("");

    const loadUsers = useCallback(async () => {
        const usersFromApi = await userAPI.List();
        const userOptions = usersFromApi.map((user) => ({
            value: +user.id,
            label: `${user.name}`
        }));

        setUsers(userOptions);

        if (admin !== 1) {
            const myOption = userOptions.filter((user) => user.value === Credentials.id)[0];
            setUser(myOption);
        }
    }, [admin]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const loadStatusList = useCallback(async () => {
        const statusListFromApi = await API.ListStatus();
        const statusOptions = statusListFromApi.list.map((status) => ({
            value: +status.id,
            label: `${status.name}`
        }));

        setStatusList(statusOptions);
    }, []);

    useEffect(() => {
        loadStatusList();
    }, [loadStatusList]);

    const loadList = useCallback(async () => {
        setIsLoading(true);

        setReviews([]);
        const productData = await API.List(
            page,
            user?.value || null,
            tab === "todo" ? 1 : tab === "tosend" ? 2 : tab === "pending" ? 3 : 0,
            delayedSearchTerm
        );
        setReviews(productData.list);
        setQuantity(productData.quantity);

        setTotalpages(Math.ceil(productData.quantity / 10));

        setIsLoading(false);
    }, [page, user, tab, delayedSearchTerm]);

    useEffect(() => {
        setPage(1);
    }, [user]);

    const changeTab = useCallback(
        (tabName: TTab) => {
            if (tabName !== tab) {
                setReviews([]);
                setPage(1);
                setTab(tabName);
            } else {
                if (page !== 1) {
                    setPage(1);
                } else loadList();
            }
        },
        [loadList, page, tab]
    );

    useEffectWithInterval(() => setDelayedSearchTerm(searchTerm), [searchTerm], 1000);

    useEffectWithInterval(loadList, [loadList, page, user, tab, delayedSearchTerm], 100);

    return (
        <Page showNav={true}>
            <div className={styles["reviews"]}>
                <ul className="nav nav-pills m-1 ">
                    <li className="nav-item ">
                        <button
                            className={classNames("nav-link", tab === "all" && "active")}
                            aria-current="page"
                            onClick={() => changeTab("all")}
                        >
                            All
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={classNames("nav-link", tab === "todo" && "active")}
                            onClick={() => changeTab("todo")}
                        >
                            To-do
                        </button>
                    </li>
                    {admin === 1 ? (
                        <>
                            <li className="nav-item">
                                <button
                                    className={classNames("nav-link", tab === "tosend" && "active")}
                                    onClick={() => changeTab("tosend")}
                                >
                                    Submitted
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={classNames(
                                        "nav-link",
                                        tab === "pending" && "active"
                                    )}
                                    onClick={() => changeTab("pending")}
                                >
                                    Pending
                                </button>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="nav-item">
                                <button
                                    className={classNames("nav-link", tab === "tosend" && "active")}
                                    onClick={() => changeTab("tosend")}
                                >
                                    Submitted
                                </button>
                            </li>
                        </>
                    )}
                </ul>
                <Select
                    isSearchable={false}
                    className={classNames("m-1", styles["list-category"])}
                    value={user}
                    options={users}
                    isDisabled={!admin}
                    placeholder={"Select user"}
                    onChange={(newValue) => setUser(newValue)}
                    isClearable
                />
                <div className={styles["list-header"]}>
                    <div>
                        Displaying {reviews.length} of {quantity} products.
                    </div>
                    <div className={styles["search-term"]}>
                        <input
                            className={styles["search-input"]}
                            placeholder="Search term"
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles["review-list"]}>
                    {reviews &&
                        reviews.map((review, index) => (
                            <div className={styles["review-row"]} key={index}>
                                <div className={styles["product-image"]}>
                                    {review.name && (
                                        <img
                                            alt={"img error"}
                                            src={`https://file.llamastories.com/${review.ASIN}.jpg`}
                                        ></img>
                                    )}
                                </div>
                                <div className={styles["product-details"]}>
                                    <a
                                        target="_blank"
                                        href={`https://www.amazon.com/dp/${review.ASIN}`}
                                        rel="noreferrer noopener"
                                        title={review.name}
                                        className={styles["product-name"]}
                                    >
                                        {review.name ||
                                            `${review.ASIN} - This item is no longer available`}
                                    </a>
                                    <div className={styles["inner-details"]}>
                                        <div className={styles["details"]}>
                                            <div className={styles["details-row"]}>
                                                <div className={styles["data"]}>
                                                    <div>{review.requested_date}</div>{" "}
                                                    {!admin && (
                                                        <>
                                                            {tab !== "todo" && (
                                                                <div
                                                                    className={
                                                                        styles["status-text"]
                                                                    }
                                                                >
                                                                    {review.review_status_name}
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    {tab === "todo" && (
                                                        <>
                                                            {admin === 1 ? (
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    href={`https://www.amazon.com/review/create-review?asin=${review.ASIN}`}
                                                                >
                                                                    Write review
                                                                </a>
                                                            ) : (
                                                                <a href={buildReviewLink(review)}>
                                                                    Write review
                                                                </a>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                {admin > 0 && (
                                                    <>
                                                        {admin === 1 ? (
                                                            <div
                                                                className={styles["admin-controls"]}
                                                            >
                                                                <Select
                                                                    isSearchable={false}
                                                                    isClearable={false}
                                                                    className={classNames(
                                                                        "m-1",
                                                                        styles["list-status"]
                                                                    )}
                                                                    value={statusList.filter(
                                                                        (option) =>
                                                                            option.value ===
                                                                            review.review_status
                                                                    )}
                                                                    options={statusList}
                                                                    isDisabled={
                                                                        isLoading || admin !== 1
                                                                    }
                                                                    placeholder={"Select status"}
                                                                    onChange={async (newValue) => {
                                                                        setIsLoading(true);
                                                                        const newStatus =
                                                                            newValue?.value || 0;

                                                                        const result =
                                                                            await API.UpdateStatus(
                                                                                review.ASIN,
                                                                                newStatus
                                                                            );
                                                                        if (result) {
                                                                            loadList();
                                                                        } else {
                                                                            alert(
                                                                                "Error updating status."
                                                                            );
                                                                        }

                                                                        setIsLoading(false);
                                                                    }}
                                                                />
                                                                <Select
                                                                    isSearchable={false}
                                                                    isClearable={false}
                                                                    className={classNames(
                                                                        "m-1",
                                                                        styles["list-user"]
                                                                    )}
                                                                    value={users.filter(
                                                                        (option) =>
                                                                            option.value ===
                                                                            review.user_id
                                                                    )}
                                                                    options={users}
                                                                    isDisabled={
                                                                        isLoading || admin !== 1
                                                                    }
                                                                    placeholder={"Select user"}
                                                                    onChange={async (newValue) => {
                                                                        setIsLoading(true);
                                                                        const newuUser =
                                                                            newValue?.value || 0;

                                                                        const result =
                                                                            await OrderAPI.UpdateUser(
                                                                                review.ASIN,
                                                                                newuUser
                                                                            );
                                                                        if (result) {
                                                                            loadList();
                                                                        } else {
                                                                            alert(
                                                                                "Error updating user."
                                                                            );
                                                                        }
                                                                        setIsLoading(false);
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            admin === 2 && (
                                                                <div>
                                                                    <div>
                                                                        {
                                                                            statusList.filter(
                                                                                (staus) =>
                                                                                    staus.value ===
                                                                                    review.review_status
                                                                            )[0].label
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            users.filter(
                                                                                (user) =>
                                                                                    user.value ===
                                                                                    review.user_id
                                                                            )[0].label
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {tab === "todo" && admin !== 1 && (
                                            <div className={styles["actions"]}>
                                                <button
                                                    disabled={isLoading}
                                                    className={classNames(
                                                        "btn",
                                                        "btn-primary",
                                                        styles["mark-as-submitted"]
                                                    )}
                                                    onClick={async () => {
                                                        await API.MarkAsSubmitted(review.ASIN);
                                                        loadList();
                                                    }}
                                                >
                                                    I sent
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={styles["list-footer"]}>
                    <ReactPaginate
                        key={page}
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={({ selected }) => setPage(selected + 1)}
                        initialPage={page - 1}
                        pageRangeDisplayed={2}
                        pageCount={totalpages}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName={classNames("pagination", styles["pagination"])}
                        activeClassName="active"
                    />
                </div>
            </div>
        </Page>
    );
};

export default Reviews;
