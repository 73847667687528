import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Products from "../Page/Products";
import Login from "../Page/Login";
import ProtectedRoute from "./SubComponents/ProtectedRoute";
import UnprotectedRoute from "./SubComponents/UnprotectedRoute";
import Orders from "../Page/Orders";
import Reviews from "../Page/Reviews";
import Product from "../Page/Product/Product";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/home" element={<ProtectedRoute />}>
                    <Route path="/home" element={<Navigate to="/reviews" />}></Route>
                    <Route path="/home/*" element={<Navigate to="/reviews" />}></Route>
                </Route>
                <Route path="/products" element={<ProtectedRoute />}>
                    <Route path="/products" element={<Products />}></Route>
                </Route>
                <Route path="/product/:asin" element={<ProtectedRoute />}>
                    <Route path="/product/:asin" element={<Product />}></Route>
                </Route>
                <Route path="/orders" element={<ProtectedRoute />}>
                    <Route path="/orders" element={<Orders />}></Route>
                </Route>
                <Route path="/reviews" element={<ProtectedRoute />}>
                    <Route path="/reviews" element={<Reviews />}></Route>
                </Route>
                <Route path="/" element={<UnprotectedRoute />}>
                    <Route path="/" element={<Login />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
