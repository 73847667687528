import { useCallback, useEffect, useState } from "react";
import { IProduct, Product as API } from "../../Api/Product";
import Page from "../../Component/Page/Page";
import styles from "./products.module.scss";
import { baseUrl } from "../../Api/BaseApi";
import classNames from "classnames";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import useEffectWithInterval from "../../hook/useEffectWithInterval";

interface IOption {
    value: string;
    label: string;
}

type TTab = "new" | "hidden";

const Products = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [categories, setCategories] = useState<IOption[]>([]);
    const [subcategories, setSubcategories] = useState<IOption[]>([]);
    const [category, setCategory] = useState<IOption | null>(null);
    const [subcategory, setSubcategory] = useState<IOption | null>(null);
    const [quantity, setQuantity] = useState<number>(0);
    const [tab, setTab] = useState<TTab>("new");
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [delayedSearchTerm, setDelayedSearchTerm] = useState("");
    const [totalpages, setTotalpages] = useState<number>(1);

    const loadCategories = useCallback(async () => {
        const categoriesFromApi = await API.Categories(tab === "hidden");
        const categoryOptions = categoriesFromApi.map((category) => ({
            value: category.code,
            label: `${category.name} (${category.quantity})`
        }));

        setCategories(categoryOptions);
    }, [tab]);

    const loadSubcategories = useCallback(async () => {
        const categoriesFromApi = await API.Subcategories(category?.value || "", tab === "hidden");
        const categoryOptions = categoriesFromApi.map((category) => ({
            value: category.code,
            label: `${category.name} (${category.quantity})`
        }));

        setSubcategories(categoryOptions);
    }, [category, tab]);

    const loadList = useCallback(async () => {
        setIsLoading(true);

        setProducts([]);
        const productData = await API.List(
            category?.value || "",
            subcategory?.value || "",
            page,
            tab === "hidden",
            delayedSearchTerm
        );
        setProducts(productData.list);
        setQuantity(productData.quantity);

        setTotalpages(Math.ceil(productData.quantity / 60));

        setIsLoading(false);
    }, [category, subcategory, tab, page, delayedSearchTerm]);

    const changeTab = useCallback((tabName: TTab) => {
        setPage(1);
        setCategory(null);
        setSubcategory(null);
        setTab(tabName);
    }, []);

    useEffectWithInterval(() => setDelayedSearchTerm(searchTerm), [searchTerm], 1000);

    useEffectWithInterval(loadList, [category, subcategory, loadList, delayedSearchTerm], 100);

    useEffect(() => {
        loadCategories();
    }, [loadCategories]);

    useEffect(() => {
        setSubcategory(null);

        if (category) {
            loadSubcategories();
        } else {
            setSubcategories([]);
        }
    }, [category, loadSubcategories]);

    return (
        <Page showNav={true}>
            <div className={styles["products"]}>
                <div>
                    <ul className="nav nav-pills m-1 ">
                        <li className="nav-item ">
                            <button
                                className={classNames("nav-link", tab === "new" && "active")}
                                aria-current="page"
                                onClick={() => changeTab("new")}
                            >
                                New items
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={classNames("nav-link", tab === "hidden" && "active")}
                                onClick={() => changeTab("hidden")}
                            >
                                Hidden items
                            </button>
                        </li>
                    </ul>
                    <Select
                        isSearchable={false}
                        className={classNames("m-1", styles["list-category"])}
                        value={category}
                        options={categories}
                        placeholder={"Select category"}
                        onChange={(newValue) => setCategory(newValue)}
                        isClearable
                    />
                    {subcategories.length ? (
                        <Select
                            isSearchable={false}
                            className={classNames("m-1", styles["list-subcategory"])}
                            value={subcategory}
                            options={subcategories}
                            placeholder={"Select sub-category"}
                            onChange={(newValue) => setSubcategory(newValue)}
                            isClearable
                        />
                    ) : (
                        <></>
                    )}
                    <div className={classNames("m-1", styles["search-term"])}>
                        <input
                            className={styles["search-input"]}
                            placeholder="Search term"
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className={styles["list-header"]}>
                        Displaying {products.length} of {quantity} products.
                    </div>
                    <div className={styles["product-list"]}>
                        {products &&
                            products.map((product, index) => (
                                <div key={index} className={styles["product"]}>
                                    {tab === "new" && (
                                        <button
                                            disabled={isLoading}
                                            className={styles["btn-hide"]}
                                            onClick={async () => {
                                                setIsLoading(true);
                                                await API.HideForUser([product.ASIN]);

                                                const newProducts = products.filter(
                                                    (thisProduct) =>
                                                        thisProduct.ASIN !== product.ASIN
                                                );

                                                setProducts(newProducts);
                                                setQuantity(quantity - 1);
                                                setIsLoading(false);

                                                if (newProducts.length === 0) {
                                                    loadList();
                                                }
                                            }}
                                        >
                                            Hide
                                        </button>
                                    )}
                                    <div className={styles["product-image"]}>
                                        <img
                                            alt={"img error"}
                                            src={`https://file.llamastories.com/${product.ASIN}.jpg`}
                                        ></img>
                                    </div>
                                    <a
                                        target="_blank"
                                        href={`https://www.amazon.com/dp/${product.ASIN}`}
                                        rel="noreferrer noopener"
                                        title={product.name}
                                        className={styles["product-name"]}
                                    >
                                        {product.name}
                                    </a>
                                    <span className={styles["product-time"]}>
                                        {product.seen_date}
                                    </span>
                                    <a
                                        className={classNames(
                                            "btn",
                                            "btn-secondary",
                                            styles["btn-details"]
                                        )}
                                        href={`/product/${product.ASIN}`}
                                        target={"_blank"}
                                        rel="noreferrer noopener"
                                    >
                                        Details
                                    </a>
                                </div>
                            ))}
                    </div>
                    <div className={styles["list-footer"]}>
                        {tab === "hidden" ? (
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={({ selected }) => setPage(selected + 1)}
                                initialPage={page - 1}
                                pageRangeDisplayed={2}
                                pageCount={totalpages}
                                previousLabel="<"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName={classNames("pagination", styles["pagination"])}
                                activeClassName="active"
                            />
                        ) : (
                            <button
                                disabled={isLoading}
                                className={classNames("btn", "btn-primary", styles["btn-hide-all"])}
                                onClick={async () => {
                                    await API.HideForUser(products.map((product) => product.ASIN));
                                    loadList();
                                }}
                            >
                                Hide all products in this page
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Products;
