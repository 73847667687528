import { useCallback, useEffect, useState } from "react";
import { IProduct, ISeenHistory, Product as API } from "../../Api/Product";
import Page from "../../Component/Page/Page";
import styles from "./product.module.scss";
import { baseUrl } from "../../Api/BaseApi";
import { useParams } from "react-router-dom";

const Product = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    let { asin } = useParams();
    const [product, setProduct] = useState<IProduct | null>(null);
    const [history, setHistory] = useState<ISeenHistory[] | undefined>(undefined);

    const loadProduct = useCallback(async () => {
        if (asin) {
            setIsLoading(true);

            const productData = await API.get(asin);
            const history = await API.ListSeenHistory(asin);
            setProduct(productData);
            setHistory(history);

            setIsLoading(false);
        }
    }, [asin]);

    useEffect(() => {
        loadProduct();
    }, [loadProduct]);

    return (
        <Page showNav={true}>
            {product && !isLoading && (
                <div className={styles["product"]}>
                    <img
                        alt={"img error"}
                        src={`https://file.llamastories.com/${product.ASIN}.jpg`}
                    ></img>
                    <div>{product?.ASIN}</div>
                    <div>{product?.name}</div>
                    <div className={styles["history"]}>
                        {history?.map((historyItem) => (
                            <div>
                                <div>{`seen ${historyItem.seen_date} on ${historyItem.tab}  ${
                                    historyItem.category
                                        ? " > " + historyItem.category.replace(/&amp;/g, "&")
                                        : ""
                                } ${
                                    historyItem.subcategory
                                        ? " > " + historyItem.subcategory.replace(/&amp;/g, "&")
                                        : ""
                                } >  page ${historyItem.page}`}</div>
                            </div>
                        ))}
                    </div>
                    <a
                        target="_blank"
                        href={`https://www.amazon.com/dp/${product.ASIN}`}
                        rel="noreferrer noopener"
                        title={product.name}
                        className={styles["product-name"]}
                    >
                        Open on amazon
                    </a>
                </div>
            )}
        </Page>
    );
};

export default Product;
