import { get, put, TResult } from "./BaseApi";

export interface IProduct {
    ASIN: string;
    name: string;
    seen_date?: string;
}

interface IList {
    list: IProduct[];
    quantity: number;
}

interface ICategory {
    code: string;
    name: string;
    quantity: number;
}

interface IHistory {
    history: ISeenHistory[];
}

export interface ISeenHistory {
    tab: string;
    category: string;
    subcategory: string;
    page: number;
    seen_date: string;
}

class ProductAPI {
    public async ListSeenHistory(ASIN: string): Promise<ISeenHistory[]> {
        const apiResult = await get<TResult<IHistory>>(`product/${ASIN}/seenHistory`);

        if (apiResult.status === 200) {
            return apiResult.data.result.history;
        } else {
            return [];
        }
    }

    public async get(asin: string): Promise<IProduct | null> {
        const apiResult = await get<TResult<IProduct[]>>(`product/${asin}`);

        if (apiResult.status === 200) {
            return apiResult.data.result[0];
        } else {
            return null;
        }
    }

    public async List(
        category: string,
        subcategory: string,
        page: number,
        showhidden: boolean,
        searchTerm: string
    ): Promise<IList> {
        const treatedSearchTerm = searchTerm ? `%${searchTerm}%` : "";
        const apiResult = await get<TResult<IList>>(
            `product?category=${category}&subcategory=${subcategory}&page=${page}&showhidden=${showhidden}&searchterm=${treatedSearchTerm}`
        );

        if (apiResult.status === 200) {
            return {
                list: apiResult.data.result.list,
                quantity: apiResult.data.result.quantity
            };
        } else {
            return {
                list: [],
                quantity: 0
            };
        }
    }

    public async Categories(showhidden: boolean): Promise<ICategory[]> {
        const apiResult = await get<TResult<ICategory[]>>(
            `product/categories?showhidden=${showhidden}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return [];
        }
    }

    public async Subcategories(category: string, showhidden: boolean): Promise<ICategory[]> {
        const apiResult = await get<TResult<ICategory[]>>(
            `product/subcategories?category=${category}&showhidden=${showhidden}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return [];
        }
    }

    public async HideForUser(ASINs: string[]): Promise<boolean> {
        const apiResult = await put<boolean>("product/hideForUser", { ASINs });

        if (apiResult.status === 200) {
            return true;
        } else {
            return false;
        }
    }
}

export const Product = new ProductAPI();
