import { get, post, TResult } from "./BaseApi";

export type TTab = "all" | "free" | "paid" | "unpaid";
export interface IOrder {
    ASIN: string;
    name: string;
    price: number;
    user_id: number;
    user_name: string;
    requested_date: string;
    paid: boolean;
    paid_date: string;
}

interface IList {
    list: IOrder[];
    quantity: number;
    total_owed: number;
    total_taxable: number;
}

class OrderAPI {
    public async List(
        page: number,
        user: number | null,
        tab: TTab,
        searchTerm: string
    ): Promise<IList> {
        const treatedSearchTerm = searchTerm ? `%${searchTerm}%` : "";
        const apiResult = await get<TResult<IList>>(
            `order?tab=${tab}&user=${user === null ? "" : user}&page=${
                page === null ? "" : page
            }&searchterm=${treatedSearchTerm}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0,
                total_owed: 0,
                total_taxable: 0
            };
        }
    }
    public async UpdateUser(ASIN: string, user: number): Promise<boolean> {
        const apiResult = await post<TResult<string>>(`order/${ASIN}/updateUser`, { user });

        if (apiResult.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    public async UpdatePaid(ASIN: string, date: string): Promise<boolean> {
        const apiResult = await post<TResult<string>>(`order/${ASIN}/updatePaid`, {
            paid_date: date
        });

        if (apiResult.status === 200) {
            return true;
        } else {
            return false;
        }
    }
}

export const Order = new OrderAPI();
