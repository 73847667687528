import { get, TResult } from "./BaseApi";

export interface IUser {
    id: string;
    name: string;
}

class UserAPI {
    public async List(): Promise<IUser[]> {
        const apiResult = await get<TResult<IUser[]>>(`user`);

        if (apiResult.status === 200) {
            return apiResult.data.result.sort((a, b) => (a.name < b.name ? -1 : +1));
        } else {
            return [];
        }
    }
}

export const User = new UserAPI();
