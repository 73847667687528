import axios from "axios";
import qs from 'qs';
import { Credentials } from "../Store/Credentials";

export const baseUrl = "https://vineapi.davila.one/";

type TReturn<T> = {
    data: T,
    status: number
}

export type TResult<T> = {
    result: T;
}

const formatReturn = <T>(returnPayload: any):TReturn<T> => {
    return {
        data: returnPayload.data,
        status: returnPayload.status
    }
}

export const get = async<T>(url: string, authorized: boolean = true): Promise<TReturn<T>> => {
    const options = {
        method: "GET",
        headers: { 
            "Authorization": authorized ? `Bearer ${Credentials.bearerToken}` : "" },
        url: baseUrl + url
    };

    try {
        const returnPayload = formatReturn<T>(await axios(options));
        return returnPayload;
    }
    catch (e: any) {
        console.log(e);
        if(e.response.status === 401) {
            Credentials.LogOut();
        }
        return {data: null as T, status: e.response.status}
    }


}

export const post = async<T>(url: string, data: any, authorized: boolean = true, redirectsWhenError:boolean = true): Promise<TReturn<T>> => {
    const options = {
        method: "POST",
        data: qs.stringify(data),
        headers: { 
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": authorized ? `Bearer ${Credentials.bearerToken}` : "" },
        url: baseUrl + url
    };



    try {
        const returnPayload = formatReturn<T>(await axios(options));
        return returnPayload;
    }
    catch (e: any) {
        console.log(e);
         if(e.response.status === 401 && redirectsWhenError) {
            Credentials.LogOut();
        }
        return {data: null as T, status: e.response.status}
    }
}

export const put = async<T>(url: string, data: any, authorized: boolean = true): Promise<TReturn<T>> => {
    const options = {
        method: "PUT",
        data: qs.stringify(data),
        headers: { 
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": authorized ? `Bearer ${Credentials.bearerToken}` : "" },
        url: baseUrl + url
    };



    try {
        const returnPayload = formatReturn<T>(await axios(options));
        return returnPayload;
    }
    catch (e: any) {
        console.log(e);
         if(e.response.status === 401) {
            Credentials.LogOut();
        }
        return {data: null as T, status: e.response.status}
    }
}